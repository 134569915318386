import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.43.0_react-dom@18._ac3vtbvzkpc75xjsizqmmayrae/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.43.0_react-dom@18._ac3vtbvzkpc75xjsizqmmayrae/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icon_dark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo_type_dark.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/others/CookieBanner.tsx");

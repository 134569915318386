/* eslint-disable @stylistic/key-spacing */
//! Change this
const baseUrl = "https://hoangnhan.uk"

export const siteConfig = {
    // Base links
    get url() {
        return process.env.NODE_ENV === "production" ? baseUrl : this.localHostUrl
    },
    get domain() {
        return this.url.replace(/(.*)+\:\/\//, "")
    },
    localHostUrl: "http://localhost:3000",

    // Personal information
    name     : "Nguyễn Hoàng Nhân",
    shortName: "Hoàng Nhân",
    birthDay : "20/03/2003",
    get birthYear() {
        return Number(this.birthDay.match(/\d{4}/)?.[0])
    },
    get age() {
        return new Date().getFullYear() - this.birthYear
    },
    role     : "Creative Designer",
    email    : {
        personal: "nhnhana5@gmail.com",
        school  : "nhannhse171176@fpt.edu.vn",
        work    : "workwith.hnhan@gmail.com"
    },
    tel: "0817818898",
    get fullTel() {
        return "+84" + " " + this.tel.replace(/^0?/, "").replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
    },

    // Base site information
    title: "Hoàng Nhân | Creative Designer",
    description: "This website is Hoang Nhan's Portfolio Space, come enjoy my artworks",

    // Social media
    link: {
        facebook : "https://facebook.com/hoangnhan2ka3",
        instagram: "https://instagram.com/hoangnhan2ka3",
        linkedIn : "https://linkedin.com/in/hoangnhan2ka3",
        behance  : "https://behance.net/nhnhan",
        dribbble : "https://dribbble.com/hoangnhan2ka3",
        github   : "https://github.com/hoangnhan2ka3",
        pinterest: "https://pinterest.com/hoangnhan2ka3",
        telegram : "https://t.me/hoangnhan2ka3",
        zalo     : "https://zalo.me/0817818898"
    },

    // Analytics ID
    get googleVerification() {
        switch (this.url) {
            case "https://hoangnhane.id.vn":
            case this.localHostUrl:
                return "eQWQgOEPxHImqJ2L8COXOXrRvoG51zYXxSX6OwSPyUo"
            case "https://hoangnhan.uk":
                return "wIoxYHsU2tTDLcBhE4WVrrW_R0nxsWnv8N-OMXL-B7I"
            case "https://hoangnhan.co.uk":
                return "-5lN5gHXa8vQrJtFCnMfO7kE60YcFFfNBvjpxW18NtI"
            default:
                throw new Error("Google Verification ID is not assignable to this domain")
        }
    },
    get yandexVerification() {
        switch (this.url) {
            case "https://hoangnhane.id.vn":
            case this.localHostUrl:
                return "fb6729bde7a90a52"
            case "https://hoangnhan.uk":
                return "1e2a28d07f5c863d"
            case "https://hoangnhan.co.uk":
                return "487575c9ced54723"
            default:
                throw new Error("Yandex Verification ID is not assignable to this domain")
        }
    },
    get googleTagManager() {
        switch (this.url) {
            case "https://hoangnhane.id.vn":
            case this.localHostUrl:
                return "GTM-TVP4K3H6"
            case "https://hoangnhan.uk":
                return "GTM-PWC8C5LQ"
            case "https://hoangnhan.co.uk":
                return "GTM-WXSFF6BZ"
            default:
                throw new Error("Google Tag Manager ID is not assignable to this domain")
        }
    },
    get googleAnalytics() {
        switch (this.url) {
            case "https://hoangnhane.id.vn":
            case this.localHostUrl:
                return "G-M8GZQ7E1MD"
            case "https://hoangnhan.uk":
                return "G-PWLJMJD5QL"
            case "https://hoangnhan.co.uk":
                return "G-7F7C52KM4N"
            default:
                throw new Error("Google Analytics ID is not assignable to this domain")
        }
    },
    get cloudFlareInsights() {
        switch (this.url) {
            case "https://hoangnhane.id.vn":
            case this.localHostUrl:
                return '{"token":"d03afe61bd644ac6b165fe710cece3a8"}'
            case "https://hoangnhan.uk":
                return '{"token":"ff78e62d859348a8800ad13e5bd536e9"}'
            case "https://hoangnhan.co.uk":
                return '{"token":"e63fb024ece44c38b593e72e1f682f04"}'
            default:
                throw new Error("CloudFlare Insights Token is not assignable to this domain")
        }
    }
}

export type SiteConfig = typeof siteConfig

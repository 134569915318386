"use client"

import React from "react"

import ModeToggle from "@/components/ui/ModeToggle"
import ReloadToggle from "@/components/ui/ReloadToggle"
import { clsx } from "@/libs/clsx"

// const ModeToggle = dynamic(() => import("@/components/ModeToggle"), {
//     ssr: false,
//     // Make sure to code a placeholder so the UI doesn't jump when the component loads
//     // loading: () => <div className="w-9 h-9" />,
// });

export default function Settings() {
    const settings = [
        {
            toggle: <ModeToggle />,
            label: "Mode",
            title: "Toggle light / dark mode",
            key: "M"
        },
        {
            toggle: <ReloadToggle />,
            label: "Catch error?",
            title: "Refresh route",
            key: null
        }
    ]

    return (
        <div className={clsx(
            "flex gap-6"
        )}>
            {settings.reverse().map((setting, index) => (
                <div key={index} content={setting.label} className={clsx(
                    "relative h-fit flex items-center text-xs capitalize",
                    "before:whitespace-nowrap before:content-[attr(content)]"
                )}>
                    {(setting.key !== null || setting.key) &&
                        <kbd className="ml-1" title={`Press ${setting.key} key`}>
						    {setting.key}
                        </kbd>
                    }
                    {React.cloneElement(setting.toggle, {
                        title: setting.title,
                        className: "ml-3"
                    })}
                </div>
            ))}
        </div>
    )
}

"use client"

import { sendGTMEvent } from "@next/third-parties/google"
import { useCallback, useEffect, useRef, useState } from "react"
import { FaMoon } from "react-icons/fa6"
import { MdSunny } from "react-icons/md"

import { siteConfig } from "@/config/site.config"
import { clsx } from "@/libs/clsx"

export function useTheme() {
    const [theme, setTheme] = useState(typeof window === "undefined" ? "light" : window.a || "light")

    const toggleTheme: any = useCallback(() => {
        if (typeof window !== "undefined" && window.c) {
            window.c(theme === "light" ? "dark" : "light")
        }
    }, [theme])

    useEffect(() => {
        window.b = setTheme
    }, [])

    return { theme, toggleTheme }
}

export default function ModeToggle(props: any) {
    const { theme, toggleTheme } = useTheme()
    const btnRef = useRef<HTMLButtonElement>(null)
    const iconWrapperRef = useRef<HTMLDivElement>(null)
    const btnIndicatorRef = useRef<HTMLDivElement>(null)

    const [icon, setIcon] = useState<null | JSX.Element>(null)

    useEffect(() => {
        const btn = btnRef.current
        const iconWrapper = iconWrapperRef.current
        const btnIndicator = btnIndicatorRef.current

        // Set the icon after the component has mounted
        setIcon(theme === "dark" ? <FaMoon /> : <MdSunny />)

        if (theme === "dark") {
            btnIndicator?.classList.add("translate-x-5")
        } else {
            btnIndicator?.classList.remove("translate-x-5")
        }

        let currentTheme: "dark" | "light" | null = null

        function handleSpinAnimation() {
            const newTheme = theme

            if (newTheme !== currentTheme) {
                currentTheme = newTheme

                if (newTheme === "dark") {
                    iconWrapper?.classList.add("animate-spin")
                    btn?.classList.remove("ring-nhn-primary/50")
                    btn?.classList.add("ring-nhn-primary/85")
                    setTimeout(() => {
                        iconWrapper?.classList.remove("animate-spin")
                        btn?.classList.remove("ring-nhn-primary/85")
                        btn?.classList.add("ring-nhn-primary/50")
                    }, 500)
                } else if (newTheme === "light") {
                    iconWrapper?.classList.add("animate-spin-counter")
                    btn?.classList.remove("ring-nhn-primary/50")
                    btn?.classList.add("ring-nhn-primary/85")
                    setTimeout(() => {
                        iconWrapper?.classList.remove("animate-spin-counter")
                        btn?.classList.remove("ring-nhn-primary/85")
                        btn?.classList.add("ring-nhn-primary/50")
                    }, 500)
                }
            }
        }

        // Initialize the observer
        const observer = new MutationObserver(handleSpinAnimation)

        // Start observing the `data-theme` attribute for changes
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ["data-theme"] })

        handleSpinAnimation()

        const handleClick = () => {
            handleSpinAnimation()
        }

        btn?.addEventListener("click", handleClick)

        return () => {
            btn?.removeEventListener("click", handleClick)
            observer.disconnect()
        }
    }, [theme])

    function disableAnimation() {
        const html = document.querySelector("html")
        html?.classList.add("no-transition")
        setTimeout(() => {
            html?.classList.remove("no-transition")
        }, siteConfig.age)
    }

    useEffect(() => {
        const handleKeyUp = (event: any) => {
            if (event.key === "m" || event.key === "M") {
                if (theme !== (theme === "dark" ? "light" : "dark")) {
                    disableAnimation()
                    toggleTheme()
                    sendGTMEvent({
                        event: "Press M key",
                        value: theme === "dark" ? "Change theme to Light" : "Change theme to Dark"
                    })
                }
            }
        }

        window.addEventListener("keyup", handleKeyUp)

        return () => {
            window.removeEventListener("keyup", handleKeyUp)
        }
    }, [theme, toggleTheme])

    return (
        <button ref={btnRef} title={props.title} onClick={() => {
            disableAnimation()
            toggleTheme()
            sendGTMEvent({ event: "Change Theme Mode", value: theme === "dark" ? "Change theme to Light" : "Change theme to Dark" })
        }} className={clsx(
            props.className,
            "h-6 min-h-6 min-w-11 w-11 border-transparent border-2",
            "relative flex rounded-full bg-nhn-background/60 ring-nhn-primary/50 duration-300 ease-out transition-box-shadow ring-1.5",
            "motion-reduce:transition-none",
            "motion-safe:hover:ring-nhn-primary/85",
            "all:cursor-inherit"
        )}>
            <div ref={btnIndicatorRef} className={clsx(
                "aspect-1 h-full rounded-full bg-nhn-secondary p-0.5 outline-[0.02rem] outline-nhn-primary outline-offset-[-0.05rem] outline transition-transform duration-250"
            )}>
                <div ref={iconWrapperRef} className={clsx(
                    "aspect-1 flex animate-duration-450 animate-ease-bounce-sm items-center justify-center text-base text-nhn-tertiary will-change-transform",
                    "motion-reduce:animate-none"
                )}>
                    {icon}
                </div>
            </div>
        </button>
    )
}

"use client"

import { sendGTMEvent } from "@next/third-parties/google"
import { motion } from "framer-motion"
import Link from "next/link"
import { useEffect, useState } from "react"
import { PiLinkSimple } from "react-icons/pi"

import { getLocalStorage, setLocalStorage } from "@/helpers/storageHelper"
import { clsx } from "@/libs/clsx"

export default function CookieBanner() {

    // Default to true
    const [cookieConsent, setCookieConsent] = useState<boolean | null>(true)

    useEffect (() => {
        const storedCookieConsent = getLocalStorage("cookie_consent", null)

        setCookieConsent(storedCookieConsent)
    }, [setCookieConsent])

    useEffect(() => {
        const newValue = cookieConsent ? "granted" : "denied"

        if (typeof window.gtag !== "undefined") {
            window.gtag("consent", "update", {
                analytics_storage: newValue
            })
            setLocalStorage("cookie_consent", cookieConsent)

            // console.log("Cookie Consent: ", cookieConsent);
        }
    }, [cookieConsent])

    const wrapper = {
        enter: {
            opacity: 0,
            y: "3.75rem"
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 2
            }
        }
    }

    return (
        <div className={clsx(
            cookieConsent !== null ? "hidden" : "flex",
            "absolute right-5 top-5 z-46 ml-5 min-w-64 flex-col items-center gap-5 rounded-2xl bg-nhn-background/20 p-2 shadow-nhn-primary backdrop-blur-lg shadow-center-8"
        )}>
            <div className={clsx(
                "px-3 pt-1 text-center"
            )}>
                <p>
                    My site use <Link href="/cookies-policy" className="text-sky-400 font-bold">cookies<PiLinkSimple className="inline-block" /></Link> to optimize experiences.
                    <br />
                    Please feel free to allow it!
                </p>
            </div>
            <div className={clsx(
                "grid grid-cols-2 w-full gap-2",
                "children:w-full children:px-5 children:py-2"
            )}>
                <button onClick={() => {
                    sendGTMEvent({ event: "Cookie Banner", value: "User doesn't like Cookies 😞" })
                    setCookieConsent(false)
                }} className={clsx(
                    "rounded-xl text-nhn-text-primary/70 outline-1 outline-nhn-accent-gray/80 outline duration-150 ease-in-out transition-[color_outline-color] -outline-offset-1",
                    "hover:text-nhn-text-primary hover:outline-nhn-accent-gray"
                )}>
                    Decline
                </button>
                <motion.button whileTap={{ scale: 0.9 }} onClick={() => {
                    sendGTMEvent({ event: "Cookie Banner", value: "User wants Cookies 😄" })
                    setCookieConsent(true)
                }} className={clsx(
                    "rounded-[0.625rem] bg-nhn-primary text-white font-bold transition-background-color",
                    "hover:bg-nhn-primary/70"
                )}>
                    Allow Cookies
                </motion.button>
            </div>
        </div>
    )
}

"use client"

import { sendGTMEvent } from "@next/third-parties/google"
import {
    AnimatePresence,
    motion,
    useDragControls,
    useMotionValue,
    useWillChange
} from "framer-motion"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useState } from "react"

import { clsx } from "@/libs/clsx"

const currentYear = new Date().toLocaleString("en-US", { timeZone: "Asia/Saigon", year: "numeric" })

export default function Footer() {
    const x = useMotionValue(0)

    const willChange = useWillChange()
    const dragControls = useDragControls()

    const currentPath = usePathname()

    const [hovered, setHovered] = useState<string | null>(null)

    return (
        <motion.footer
            drag="x"
            dragControls={dragControls}
            dragListener={false}
            dragConstraints={{ left: 0, right: 0 }}
            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
            dragElastic={0.2}
            style={{ x, willChange }}
            className={clsx(
                "absolute bottom-0 z-10 h-footer min-w-max flex items-center justify-center gap-6 px-2 text-xs text-nhn-text-primary/50 font-bold left-[theme(width.nav)] w-[calc(100%-theme(width.nav))]",
                "before:h-[0.0625rem] before:min-w-16 before:grow-1 before:rounded-r-full before:from-nhn-primary before:bg-gradient-to-l before:content-empty",
                "after:h-[0.0625rem] after:min-w-16 after:grow-1 after:rounded-l-full after:from-nhn-primary after:bg-gradient-to-r after:content-empty"
            )}
        >
            <span title={`Copyright by HOANGNHANNE (${currentYear})`} className={clsx(
                "user-select-none"
            )}>© {currentYear} HOANGNHANNE - All rights reserved</span>
            <motion.div
                onPointerDown={(e) => {
                    dragControls.start(e)
                }}
                dragListener={true}
                onClick={() => {
                    sendGTMEvent({ event: "Drag Footer", value: "Dragging to play with footer" })
                }}
                className={clsx(
                    "h-full flex cursor-grab items-center",
                    "children:cursor-inherit",
                    "active:cursor-grabbing"
                )}
            >
                <hr className={clsx(
                    "translate-y-[0.0625rem]"
                )} />
            </motion.div>
            <div className={clsx(
                "h-full flex",
                "a:h-full a:flex a:items-center",
                "center-of-type:a:px-3 first-of-type:a:pr-3 last-of-type:a:pl-3",
                "label:relative label:z-3 label:h-full label:flex label:items-center label:duration-300 label:transition-color"
            )}>
                <AnimatePresence initial={false}>
                    {[
                        { href: "/privacy-policy", label: "Privacy Policy" },
                        { href: "/cookies-policy", label: "Cookies Policy" },
                        { href: "/terms-and-conditions", label: "Terms & Conditions" },
                        { href: "/disclaimer", label: "Disclaimer" }
                    ].map((link, i) => {
                        const isActiveLink = link.href === currentPath
                        return (
                            <Link
                            // onMouseEnter={() => setHovered(link.label)}
                            // onMouseLeave={() => setHovered(null)}
                                key={link.href}
                                href={link.href}
                                className="group all:cursor-inherit"
                            >
                                <div className={clsx(
                                    "relative h-full"
                                )}>
                                    {isActiveLink && (
                                        <motion.div
                                            key={i}
                                            initial={{ y: "110%" }}
                                            animate={{ y: 0 }}
                                            exit={{ y: "110%" }}
                                            transition={{
                                                type: "spring",
                                                mass: 0.2
                                            }}
                                            layoutId="footBox"
                                            className={clsx(
                                                "absolute bottom-0 h-[90%] w-[calc(100%+1.5rem)] rounded-t-2xl from-nhn-primary bg-gradient-to-b will-change-transform -ml-3"
                                            )}
                                        />
                                    )}
                                    <label className={clsx(
                                        isActiveLink
                                            ? "text-white"
                                            : "text-nhn-text-primary/50 group-hover:text-nhn-text-primary/80"
                                    )}>
                                        {link.label}
                                    </label>
                                    {/* {link.label === hovered && (
										<motion.div
											initial={{ y: "110%" }}
											animate={{ y: 0 }}
											transition={{
												type: "spring",
												mass: 0.2,
												duration: 0.8,
											}}
											layoutId="footLine"
											className="absolute -bottom-1 -ml-2 h-2 w-[calc(100%+1rem)] rounded-full bg-nhn-primary/40"
										/>
									)} */}
                                </div>
                            </Link>
                        )
                    })}
                </AnimatePresence>
            </div>
        </motion.footer>
    )
}

import { useRouter } from "next/navigation"
import { IoReload } from "react-icons/io5"

import { clsx } from "@/libs/clsx"

export default function ReloadToggle(props: any) {
    const router = useRouter()

    function hardReload() {
        if (typeof window !== "undefined") {
            window.location.reload()
            localStorage.clear()
            sessionStorage.clear()
            router.refresh()

            // Clear cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
            })
        }
    }

    return (
        <button onClick={hardReload} title={props.title} className={clsx(
            props.className,
            "group relative h-6 min-h-6 flex origin-bottom-left items-center gap-0.75 border-transparent rounded-full bg-nhn-background/60 px-1 text-nhn-text-primary/50 ring-nhn-primary/50 duration-300 ease-out transition-[box-shadow_color] border-2 ring-1.5",
            "motion-reduce:transition-none",
            "motion-safe:hover:text-nhn-text-primary motion-safe:hover:ring-nhn-primary/85",
            "all:cursor-inherit"
        )}>
            <IoReload size="1rem" className={clsx(
                "origin-[47%_50%] text-nhn-primary will-change-transform",
                "group-hover:animate-duration-450 group-hover:animate-ease-bounce-sm group-hover:animate-spin"
            )} />
            Refresh
        </button>
    )
}

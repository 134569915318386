"use client"

import { sendGTMEvent } from "@next/third-parties/google"
import { AnimatePresence, motion, useAnimate } from "framer-motion"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { RouterNProgressOptions, useRouter } from "next-nprogress-bar"
import React, { useCallback, useEffect, useState } from "react"

import { handleProjectsScroll } from "@/components/layout/PageWrapper"
import { clsx } from "@/libs/clsx"

// 0.06dvw => 1px
// 0.1175dvw => 2px
// 0.176dvw => 3px
// 0.235dvw => 4px
// 0.3525dvw => 6px
// 0.47dvw => 8px
// 0.705dvw => 12px
// 0.94dvw => 16px
// 1.41dvw => 24px
// 1.5275dvw => 26px
// 1.88dvw => 32px
// 2.115dvw => 36px
// 2.82dvw => 48px
// 3.525dvw => 60px
// 3.76dvw => 64px
// 4.4dvw => 75px
// 7.52dvw => 128px

type Side = "top" | "bottom" | "right" | "left"
type KeyframeMap = {
    [key in Side]: string[];
}
type MouseEvent = React.MouseEvent<HTMLElement, globalThis.MouseEvent>

const NO_CLIP = "polygon(0 0, 100% 0, 100% 100%, 0 100%)"

// SINGLE, RIGHT-RIGHT
const FROM_TOP_RIGHT_CLIP = "polygon(0 0, 100% 0, 100% 100%, 100% 0)"
const TO_BOTTOM_RIGHT_CLIP = "polygon(100% 100%, 100% 0, 100% 100%, 0 100%)"

// UP TO DOWN, BOTTOM-TOP
const FROM_TOP_LEFT_CLIP = "polygon(0 0, 100% 0, 0 0, 0 100%)"
// const TO_BOTTOM_LEFT_CLIP = "polygon(0 0, 0 100%, 100% 100%, 0 100%)";

// DOWN TO UP, TOP-BOTTOM
const FROM_BOTTOM_LEFT_CLIP = "polygon(0 0, 0 100%, 100% 100%, 0 100%)"
const TO_TOP_LEFT_CLIP = "polygon(0 0, 100% 0, 0 0, 0% 100%)"

// LEFT-LEFT
const FROM_TOP_CLIP = "polygon(0 0, 100% 0, 100% 0, 0 0)"
const TO_BOTTOM_CLIP = "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)"

const ENTRANCE_KEYFRAMES: KeyframeMap = {
    left: [FROM_TOP_CLIP, NO_CLIP],
    bottom: [TO_BOTTOM_RIGHT_CLIP, NO_CLIP],
    top: [FROM_TOP_RIGHT_CLIP, NO_CLIP],
    right: [FROM_TOP_RIGHT_CLIP, NO_CLIP]
}

const EXIT_KEYFRAMES: KeyframeMap = {
    left: [NO_CLIP, TO_BOTTOM_CLIP],
    bottom: [NO_CLIP, FROM_BOTTOM_LEFT_CLIP], // old: TO_BOTTOM_LEFT_CLIP
    top: [NO_CLIP, TO_TOP_LEFT_CLIP],
    right: [NO_CLIP, TO_BOTTOM_RIGHT_CLIP]
}

export default function Sidebar() {
    const links = React.useMemo(() => [
        { href: "/", label: "Home", key: "Q" },
        { href: "/projects", label: "Projects", key: "W" },
        { href: "/tutorials", label: "Tutorials", key: "E" },
        { href: "/about", label: "About", key: "R" },
        { href: "/contact", label: "Contact", key: "T" }
    ], [])

    const scopes = links.map(() => React.createRef<HTMLDivElement>())
    const [scope, animate] = useAnimate()

    const getClosestEdge = (e: MouseEvent) => {
        const box = (e.target as HTMLElement).getBoundingClientRect()

        const proximityToLeft = {
            proximity: Math.abs(box.left - e.clientX),
            side: "left" as Side
        }
        const proximityToRight = {
            proximity: Math.abs(box.right - e.clientX),
            side: "right" as Side
        }
        const proximityToTop = {
            proximity: Math.abs(box.top - e.clientY),
            side: "top" as Side
        }
        const proximityToBottom = {
            proximity: Math.abs(box.bottom - e.clientY),
            side: "bottom" as Side
        }

        const sortedProximity = [
            proximityToLeft,
            proximityToRight,
            proximityToTop,
            proximityToBottom
        ].sort((a, b) => a.proximity - b.proximity)

        return sortedProximity[0].side
    }

    const handleMouseEnter = useCallback((index: number) => (e: MouseEvent) => {
        const side = getClosestEdge(e)

        if (scopes[index].current) {
            animate(scopes[index].current as Element, {
                clipPath: ENTRANCE_KEYFRAMES[side]
            }, { duration: 0.3, ease: "easeOut" })
        }
    }, [animate, scopes])

    const handleMouseLeave = useCallback((index: number) => (e: MouseEvent) => {
        const side = getClosestEdge(e)

        if (scopes[index].current) {
            animate(scopes[index].current as Element, {
                clipPath: EXIT_KEYFRAMES[side]
            }, { duration: 0.3, ease: "easeOut" })
        }
    }, [animate, scopes])

    let currentPath = usePathname() || "/";
    [
        "/projects",
        "/tutorials"
    ].forEach((path) => {
        if (currentPath.includes(`${path}/`)) {
            currentPath = path
        }
    })

    const router = useRouter()

    useEffect(() => {
        const progressOptions: RouterNProgressOptions = {
            showProgressBar: false,
            startPosition: 0.99
        }

        const handleKeyUp = (e: any) => {
            const link = links.find(link => link.key.toLowerCase() === e.key.toLowerCase() || link.key.toUpperCase() === e.key.toUpperCase())

            if (link) {
                e.preventDefault()
                router.push(link.href, undefined, { ...progressOptions })
                sendGTMEvent({
                    event: `Press ${link.key} key`,
                    value: `Go to ${link.label} page by ${link.key} key pressed`
                })
            }
        }

        window.addEventListener("keyup", handleKeyUp)

        return () => {
            window.removeEventListener("keyup", handleKeyUp)
        }
    }, [links, router])

    const [isHovered, setIsHovered] = useState(false)

    function waitToSetIsHovered(value: boolean) {
        setTimeout(() => {
            setIsHovered(value)
        }, 250)
    }

    // const handleClick = useCallback(() => {
    //     links.map((link) => {
    //         sendGTMEvent({ event: "Change Page", value: `Click on ${link.label} page` });
    //     });
    // }, [links]);

    const activeLinkStates = links.map((link) => link.href === currentPath)

    useEffect(() => {
        scopes.forEach((scope, i) => {
            if (scope.current && !activeLinkStates[i] && !isHovered) {
                scope.current.style.clipPath = FROM_TOP_LEFT_CLIP
            }
        })
    }, [activeLinkStates, isHovered, scopes])

    return (
        <nav
            onClick={handleProjectsScroll}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => waitToSetIsHovered(false)}
            className={clsx(
                "grid grid-rows-5 min-h-[calc(4rem*5)] w-nav place-items-center border-r-1 border-nhn-primary divide-y-1 divide-nhn-primary",
                "before:pointer-events-none before:absolute before:left-0 before:top-0 before:z-1 before:h-full before:min-w-6 before:w-16 before:from-nhn-background before:bg-gradient-to-r before:content-empty",
                "after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:z-1 after:h-16 after:min-h-6 after:w-full after:from-nhn-background after:bg-gradient-to-t after:content-empty",
                "div:absolute div:inset-0 div:z-2 div:h-full div:w-[calc(100%+0.03125rem)] div:cursor-pointer div:from-nhn-primary div:bg-gradient-to-l div:will-change-clip-path"
            )}
        >
            <AnimatePresence initial={false}>
                {links.map((link, i) => {
                    const isActiveLink = activeLinkStates[i]
                    return (
                        <Link key={link.href} prefetch={true} href={link.href}
                            onMouseEnter={handleMouseEnter(i)}
                            onMouseLeave={handleMouseLeave(i)}
                            onClick={() => {
                                sendGTMEvent({ event: "Change Page", value: `Click on ${link.label} page` })
                            }}
                            className={clsx(
                                "group relative size-full flex items-center justify-center text-lg font-bold"
                            )}
                        >
                            {isActiveLink && (
                                <motion.div
                                    key={i}
                                    initial={{ x: "-110%" }}
                                    animate={{ x: 0 }}
                                    transition={{
                                        type: "spring",
                                        mass: 0.2
                                    }}
                                    layoutId="sideBox"
                                />
                            )}
                            <div ref={isActiveLink ? undefined : scopes[i]}
                                className={clsx(
                                    isActiveLink && "animate-fade-out",
                                    "animate-duration-250 animate-ease-in opacity-60 will-change-[opacity_clip-path]"
                                )}
                                style={{
                                    clipPath: (isActiveLink && isHovered) ? NO_CLIP : FROM_TOP_LEFT_CLIP
                                }}
                            />
                            <label className={clsx(
                                isActiveLink
                                    ? "scale-110 text-nhn-text-primary"
                                    : "text-nhn-text-primary/50 group-hover:text-nhn-text-primary/80",
                                "absolute z-3 grid place-items-center will-change-[color_transform]"
                            )}>
                                {link.label}
                            </label>
                            <span className={clsx(
                                "absolute right-1 top-1 z-3 flex items-center"
                            )}>
                                <kbd title={`Press ${link.key} key`}>
                                    {link.key}
                                </kbd>
                            </span>
                        </Link>
                    )
                })};
            </AnimatePresence>
        </nav>
    )
}
